import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";
import InstructionFaceScan from "../assets/images/InstructionFaceScan.png";
import InstructionFingerScan from "../assets/images/InstructionFingerScan.png";

const ScanInstructions = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const scanType = state.scan_type;
  const [loading, setLoading] = useState(false);

  const startScan = async () => {
    try {
      setLoading(true);
      const scanTokenResp = await fetch("https://sdk-staging.carenow.healthcare/vitals/create-token", {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: state.partner_secret },
        body: JSON.stringify({ api_key: state.partner_key, employee_id: state.partner_client_id }),
      });
      const scanTokenRespJSON = await scanTokenResp.json();
      if (scanTokenRespJSON?.statusCode?.toString().startsWith("2")) {
        navigate(`/${scanType}-scan`, { state: { ...state, scan_token: scanTokenRespJSON.scan_token } });
      } else {
        throw new Error(scanTokenRespJSON?.message ?? "Error in Fetching the Scan Token");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="p-8">
      <div className="flex items-center text-primary">
        <Link className="flex-shrink-0 p-1 pr-2.5" to="/select-posture" state={state} replace>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Instructions</h3>
      </div>

      <div className="my-6 mx-auto w-48">
        <img
          className="h-36 w-36 mx-auto"
          src={scanType === "face" ? InstructionFaceScan : InstructionFingerScan}
          alt="instructions"
        />
        <h3 className="mt-4 text-primary text-sm font-medium capitalize">{scanType} Scan</h3>
        <ol className="mt-2 ml-2.5 list-outside list-decimal space-y-1.5">
          {(scanType === "face"
            ? [
                "The ambient light should be bright and consistent and must be white.",
                "Remove your masks, eye-wear, or clothing obstruction the face for the duration of scan.",
                "Keep your face and phone steady to avoid movements during the scan.",
                "For light mode: Make sure your face is mostly covered by the blue circle.",
                "If prompted, allow the camera permission.",
              ]
            : [
                "Place your finger on the rear primary camera and the flashlight.",
                "Make sure the screen looks bright red in color.",
                "If the flash is not turned on then place some light behind the phone.",
                "If prompted, allow the camera permission.",
              ]
          ).map((listItem, index) => (
            <li key={index} className="list-item text-darkgray text-xxs">
              {listItem}
            </li>
          ))}
        </ol>

        <button
          className="mt-8 mx-auto w-40 flex items-center justify-center space-x-2 rounded-full px-4 py-2 bg-secondary disabled:bg-darkgray text-white"
          type="button"
          onClick={startScan}
          disabled={loading}
        >
          <span className="text-xs font-medium">Start Scan</span>
          {loading && <Loader className="flex-shrink-0 h-4 w-4" />}
        </button>
      </div>

      <div className="mt-12 mb-6 p-6 rounded-xl bg-secondary text-white space-y-1.5">
        <h3 className="text-xs font-medium">Disclaimer</h3>
        <p className="text-xxs text-justify">
          For Investigational Use Only. Careplix is not a substitute for the clinical judgment of a healthcare
          professional. Careplix is intended to improve your awareness of general wellness. Careplix does not diagnose,
          treat, mitigate or prevent any disease, symptom, disorder or abnormal physical state. Consult with a health
          care professional or emergency services if you believe you may have a medical issue.
        </p>
      </div>
    </section>
  );
};

export default ScanInstructions;
